<template>
  <div class="scene">
    <!-- <div class="overlay_debug">{{ currentFrame }}{{ isOnMain }}</div> -->
    <div class="loading-wrap" v-if="!loaded">
      <div class="lds-dual-ring">
        <span class="txt">Loading</span>
      </div>
    </div>
    <div class="dialog-wrap item-info" v-if="infoOpen">
      <div class="dialog ui-item gradient">
        <button
          class="exit"
          @click.stop.prevent="infoOpen = !infoOpen"
        ></button>
        <div
          class="text-wrap"
          v-for="(paragraph, i) in isOnMain
            ? infoList[currentFrame - 1].main[infoSector]
            : infoList[currentFrame - 1].sub"
          :key="i"
        >
          <p class="text" dir="auto">
            {{ paragraph }}
          </p>
        </div>
      </div>
    </div>
    <div class="dialog-wrap instructions" v-if="instructionsOpen">
      <div class="dialog ui-item gradient">
        <button
          class="exit"
          @click.stop.prevent="instructionsOpen = !instructionsOpen"
        ></button>

        <p class="text" dir="auto">ברוך הבא לסיור וירטואלי - לומדת קיטור.</p>
        <p class="sub-text" dir="auto" v-if="!isMobile">
          הסיור מורכב מתמונות 360 ברחבי המפעל, גרירה של העכבר תוך כדי לחיצה תזיז
          את המבט כך שתוכל לראות את החלל ב360 מעלות. <br />
          לחיצה על הריבועים הצהובים יעבירו אתכם לתמונת תקריב של אזור העניין.
          <br />
          ריבועים אדומים אינם לחיצים אך מסמנים את אזור העניין. <br />
          מעבר בין התמונות יתבצע על ידי לחיצה על חצי המקלדת או על כפתורי 'הבא'
          ו'הקודם' בצידי המסך <br />
          ניתן גם לעבור על ידי לחיצה כל כפתור המפה בחלקו העליון של המסך ולחיצה
          על האזור הרצוי, המפה גם תשמש אותך כשתרצה להבין את מיקומך במפעל, המיקום
          במפה מתעדכן לפי התמונה בה אתה צפית בזמן שלחצת על כפתור המפה. <br />
          בסיור הוירטואלי ניתן גם למצוא את כפתורי סימן השאלה, לחיצה תציג את
          הפעולה הנדרשת לביצוע באזור העניין.
        </p>
        <p class="sub-text" dir="auto" v-else>
          הסיור מורכב מתמונות 360 ברחבי המפעל, גרירת האצבע על המסך תזיז את המבט
          כך שתוכל לראות את החלל ב360 מעלות. לחיצה על הריבועים הצהובים יעבירו
          אתכם לתמונת תקריב של אזור העניין. ריבועים אדומים אינם לחיצים אך מסמנים
          את אזור העניין. מעבר בין התמונות יתבצע על ידי לחיצה על כפתורי 'הבא'
          ו'הקודם' בצידי המסך ניתן גם לעבור על ידי לחיצה על כפתור המפה בחלקו
          העליון של המסך ולחיצה על האזור הרצוי, המפה גם תשמש אותך כשתרצה להבין
          את מיקומך במפעל, המיקום במפה מתעדכן לפי התמונה בה אתה צפית בזמן שלחצת
          על כפתור המפה. בסיור הוירטואלי ניתן גם למצוא את כפתורי סימן השאלה,
          לחיצה תציג את הפעולה הנדרשת לביצוע באזור העניין.
        </p>
      </div>
    </div>
    <div class="ui" v-if="loaded && !infoOpen">
      <div class="top ui-item">
        <!-- {{ currentFrame }} -->
        <button
          :style="`background-image: url(${'img/icons/info-full.svg'});`"
          v-if="!isMapOpen"
          class="ui-btn info"
          style=""
          @click="instructionsOpen = !instructionsOpen"
          title="מידע"
        ></button>
        <button
          :style="`background-image: url(${'img/icons/map-full.svg'});`"
          class="ui-btn map"
          @click="isMapOpen = !isMapOpen"
          title="מפה"
        ></button>
        <button
          :style="
            isFullScreen
              ? `background-image: url(${'img/icons/min-full.svg'});`
              : `background-image: url(${'img/icons/full-full.svg'});`
          "
          v-if="!isMobile"
          :class="`ui-btn gradient toggle-screen ${
            isFullScreen ? 'min' : 'full'
          }`"
          :title="!isFullScreen ? 'מסך מלא' : 'הקטן מסך'"
          @click="toggleFullScreen"
        ></button>
      </div>
      <div class="bottom" v-if="!isMapOpen">
        <button
          v-if="currentFrame !== imgList.length"
          title="הבא"
          class="next arrow ui-btn ui-item gradient"
          @click="next"
        ></button>
        <button
          v-if="currentFrame !== 1"
          title="הקודם"
          class="prev arrow ui-btn ui-item gradient"
          @click="prev"
        ></button>
      </div>
    </div>

    <a-scene
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
      loading-screen="enabled: false"
    >
      <a-assets
        timeout="15000"
        @timeout="loaded = true"
        @loaded="loaded = true"
      >
        <img id="borderRed" src="img/border_red.svg" />
        <img id="borderYellow" src="img/border_yellow.svg" />
        <canvas id="canvas" />
        <span v-for="(item, index) in imgList" :key="index">
          <img
            :id="`preload${item.name}`"
            :src="`img/frames/${item.name}.webp`"
          />
          <img
            v-if="item.hasSub"
            :id="`preload${item.step}-s`"
            :src="`img/frames/${item.step}-s.webp`"
          />
        </span>
      </a-assets>

      <a-entity id="rig">
        <a-entity
          position="0 1.7 0"
          id="camera"
          :camera="`fov: ${isMobile ? 100 : 70}; zoom: 0.7`"
          cursor="rayOrigin: mouse"
          raycaster="objects: .intractable;"
          look-controls="magicWindowTrackingEnabled: false"
          rotation-reader
        >
        </a-entity>
      </a-entity>

      <a-sky
        :src="`#preload${currentFrame}-${isOnMain ? 'm' : 's'}`"
        rotation="0 -130 0"
      ></a-sky>

      <a-entity v-if="isOnMain">
        <a-entity v-for="(item, i) in currScenario.clickAreasMain" :key="i">
          <a-image
            class="intractable"
            ref="area"
            :src="
              currScenario.SubAreas.length === 0
                ? '#borderRed'
                : '#borderYellow'
            "
            :width="item.width"
            :height="item.height"
            :position="item.position"
            :rotation="item.rotation"
            @click="clickArea"
            @mouseenter="hoverAnim(true)"
            @mouseleave="hoverAnim(false)"
            visible="true"
          >
          </a-image>
          <a-circle
            ref="info"
            class="intractable"
            src="img/objectInfo.svg"
            radius="0.3"
            :position="item.infoPos"
            :rotation="item.rotation"
            @click="(infoSector = i), (infoOpen = true)"
            @mouseenter="hoverAnimInfo(true)"
            @mouseleave="hoverAnimInfo(false)"
          >
          </a-circle>
        </a-entity>
      </a-entity>
      <a-entity v-else>
        <a-entity v-for="(item, index) in currScenario.SubAreas" :key="index">
          <a-image
            class="intractable"
            ref="subArea"
            src="#borderRed"
            :width="item.width"
            :height="item.height"
            :position="item.position"
            :rotation="item.rotation"
            visible="false"
            @mouseenter="hoverAnimToggle(true)"
            @mouseleave="hoverAnimToggle(false)"
          >
          </a-image>
          <a-circle
            ref="info"
            class="intractable"
            src="img/objectInfo.svg"
            radius="0.3"
            :position="item.infoPos"
            :rotation="item.rotation"
            @click="infoOpen = true"
            @mouseenter="hoverAnimToggle(true)"
            @mouseleave="hoverAnimToggle(false)"
          >
          </a-circle>
        </a-entity>
      </a-entity>
    </a-scene>
    <Map
      v-if="isMapOpen"
      :currentFrame="currentFrame"
      :isMobile="isMobile"
      :mapInstructions="mapInstructions"
      @mapclick="
        (currentFrame = $event), (isMapOpen = false), (isOnMain = true)
      "
      @mapinstructions="mapInstructions = false"
      @closemap="isMapOpen = false"
    />
  </div>
</template>

<script>
// require("aframe");
import Map from "@/components/Map.vue";

export default {
  name: "Scene",
  components: {
    Map,
  },
  data: () => {
    return {
      currentFrame: 1,
      isMobile: false,
      isFullScreen: false,
      isMapOpen: false,
      instructionsOpen: false,
      mapInstructions: true,
      infoColor: "#ad2e2e",
      infoColorOrigin: "#ad2e2e",
      infoColorHover: "#cc5656",
      camRoatation: null,
      isOnMain: true,
      loaded: false,
      infoOpen: false,
      infoSector: null,
      infoList: [
        {
          main: [
            [
              "מערכת מי עיבוי קונדנסר בעבודה",
              "• משאבה עובדת",
              "• וסתי גובה באוטומט",
            ],
          ],
          sub: ["יש לבדוק שהלחץ תקין"],
        },
        {
          main: [
            [
              "במידת הצורך נמלא מים במעבה.",
              "מומלץ להשאיר ברז מילוי פתוח עד ההפעלה, לצורך סחרור.",
              "לחץ על הברז לפרטים",
            ],
          ],
          sub: ["ברז מילוי למעבה"],
        },
        {
          main: [
            [
              "מלכודת 8.5 בעבודה מלאה",
              "• יש לבדוק שהברזים פתוחים.",
              "לחץ כדי להתקרב",
            ],
          ],
          sub: ["בדיקה: ברז ריקון לפלאש מתפקד"],
        },
        {
          main: [
            [
              "מעבה קיטור אטימה",
              "לפני יצירת ואקום במעבה הגדול יש להכניס את מעבה קיטור האטימה לעבודה",
            ],
          ],
        },
        {
          main: [
            ["קו קיטור 8.5:", "יש לוודא ניקוז מים מקו הקיטור"],
            ["קו קיטור 8.5:", "יש לוודא ניקוז מים מקו הקיטור"],
          ],
        },
        {
          main: [["פתיחת קו 8.5"]],
        },
        {
          main: [
            [
              "קו זה מזין את וסת  401PV – ווסת קיטור אטימה. ",
              "יש לוודא שהווסת סגור במצב ידני (mark 6)",
            ],
          ],
          sub: [""],
        },
        {
          main: [
            [
              "קו זה מזין קיטור ליצירת ואקום במעבה קיטור אטימה.",
              "לחץ כדי לראות את שעון הואקום",
            ],
          ],
          sub: ["שעון וואקום"],
        },
        {
          main: [
            [
              "בקו ה-VENT של מעבה קיטור אטימה קיים ברז ניקוז (נמצא בברך, מתחת לקונדנסר)",
              "הניקוז חייב להיות פתוח כדי לנקז מים ולהימנע מבלימה בקו.",
              "כאשר מתחיל לצאת קיטור, רצוי לסגור מעט את הברז.",
              "לחצו על הניקוז כדי לראות מקרוב",
            ],
          ],
          sub: ["ניקוז ברך ב-VENT"],
        },
        {
          main: [
            [
              "נתחיל בהזרמת הקיטור עד לווסת הקיטור למעבה קיטור אטימה.",
              "נוודא שברז ניקוז 1-DB מתחת לבית הטורבינה פתוח",
              "(הניקוז השמאלי מבין השלושה)",
            ],
          ],
        },

        {
          main: [["יש למלא מים בברך הברומטרית"]],
          sub: ["יש למלא מים בברך הברומטרית"],
        },

        {
          main: [
            [
              "לאחר מילוי מים בברך הברומטרית, יש לפתוח את ווסת הקיטור למעבה באופן ידני",
            ],
          ],
        },
        {
          main: [
            ["בתחילת הפעלת הטורבינה נעבוד עם הווסת הידני עד להתייצבות המערכת"],
            ["שעוני ואקום מעבה קיטור אטימה", "הערך לשמירה: 480-"],
          ],
        },
        {
          main: [
            [
              "יש לוודא שהניקוז אחרי ווסת השחרור פתוח",
              "(נמצא מתחת לבית הטורבינה, ליד קו הקזה 1.8).",
              "ניקוז זה חייב להישאר פתוח כל הזמן",
            ],
          ],
        },
        {
          main: [
            ["ווסת שחרור"],
            [
              "בשלב זה נתחיל בפתיחת וסת קיטור האטימה 401-PV באופן איטי עד להגעה ל-  SP=0.16",
              "כאשר הטורבינה מועמסת נשמור על ערך  של:",
              "0.12-0.14",
            ],
          ],
        },
        {
          main: [["לבדוק:", "• הנשם נקי", "• לא יוצאים אדים"]],
        },
        {
          main: [
            [
              "לאחר שבצענו את הבדיקות והכל תקין...",
              "אפשר ליצור ואקום במעבה הגדול",
            ],
          ],
        },
      ],

      imgList: [
        {
          step: 1,
          isMain: true,
          name: "1-m",
          hasSub: true,
          SubAreas: [
            {
              infoPos: "2.4 1.75 -0.1",
              position: "2 1.75 -2.1",
              rotation: "0 -60 0",
              width: 2.5,
              height: 2,
            },
          ],
          clickAreasMain: [
            {
              infoPos: "2.4 1.75 -0.1",
              position: "2.4 1.75 -2.1",
              rotation: "0 -60 0",
              width: 3.5,
              height: 3,
            },
          ],
        },
        {
          step: 2,
          isMain: true,
          name: "2-m",
          hasSub: true,
          SubAreas: [
            {
              infoPos: "2.4 2.75 -0.1",
              position: "2 3 -2.1",
              rotation: "0 -60 0",
              width: 2.5,
              height: 2,
            },
          ],
          clickAreasMain: [
            {
              infoPos: "1.869 2.319 -3.100",
              position: "2.4 5.5 -4.1",
              rotation: "0 -30 0",
              width: 4.5,
              height: 4.5,
            },
          ],
        },
        {
          step: 3,
          isMain: true,
          name: "3-m",
          hasSub: true,
          SubAreas: [
            {
              infoPos: "2.4 2 -0.1",
              position: "2 2 -2.1",
              rotation: "0 -60 0",
              width: 2.5,
              height: 2,
            },
          ],
          clickAreasMain: [
            {
              infoPos: "2.4 2.5 -1.7",
              position: "2.4 0 -1.7",
              rotation: "-20 -50 0",
              width: 3,
              height: 4.5,
            },
          ],
        },
        {
          step: 4,
          isMain: true,
          name: "4-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 3 -1.7",
              position: "4.5 2 -4.1",
              rotation: "0 -50 0",
              width: 14,
              height: 3,
            },
          ],
        },
        {
          step: 5,
          isMain: true,
          name: "5-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "0.6 2.4 -4.1",
              position: "2.7 2.8 -4.1",
              rotation: "0 -30 0",
              width: 2.8,
              height: 2.8,
            },
            {
              infoPos: "0.6 -0.4 -4.1",
              position: "2.7 -0.8 -4.1",
              rotation: "0 -30 0",
              width: 2.8,
              height: 2.8,
            },
          ],
        },
        {
          step: 6,
          isMain: true,
          name: "6-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "0.6 1.2 -2.1",
              position: "2.4 0.8 -2.1",
              rotation: "0 -40 0",
              width: 2,
              height: 2,
            },
          ],
        },
        {
          step: 7,
          isMain: true,
          name: "7-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 1.4 -1.1",
              position: "1 1 -2.1",
              rotation: "0 -40 0",
              width: 2,
              height: 2,
            },
          ],
        },
        {
          step: 8,
          isMain: true,
          name: "8-m",
          hasSub: true,
          SubAreas: [
            {
              infoPos: "2.4 2 -0.1",
              position: "2 2 -2.1",
              rotation: "0 -60 0",
              width: 2.5,
              height: 2,
            },
          ],
          clickAreasMain: [
            {
              infoPos: "3 1.4 -1.1",
              position: "1 1 -2.1",
              rotation: "0 -40 0",
              width: 3,
              height: 5,
            },
          ],
        },
        {
          step: 9,
          isMain: true,
          name: "9-m",
          hasSub: true,
          SubAreas: [
            {
              infoPos: "2 1.5 -2.1",
              position: "2 0 -2.1",
              rotation: "0 -60 0",
              width: 2.5,
              height: 2,
            },
          ],
          clickAreasMain: [
            {
              infoPos: "2 1.4 -1.1",
              position: "1 1 -2.1",
              rotation: "0 -40 0",
              width: 2,
              height: 2,
            },
          ],
        },
        {
          step: 10,
          isMain: true,
          name: "10-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 1.5 -3.1",
              position: "2 2.4 -2.1",
              rotation: "0 -40 0",
              width: 1,
              height: 1,
            },
          ],
        },
        {
          step: 11,
          isMain: true,
          name: "11-m",
          hasSub: true,
          SubAreas: [
            {
              infoPos: "5.4 2 -0.1",
              position: "5 2 -2.1",
              rotation: "0 -60 0",
              width: 3,
              height: 3,
            },
          ],
          clickAreasMain: [
            {
              infoPos: "2 3 -3.1",
              position: "1.2 0.8 -2.1",
              rotation: "0 -40 0",
              width: 3,
              height: 3,
            },
          ],
        },
        {
          step: 12,
          isMain: true,
          name: "12-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 1.3 -2.1",
              position: "2 0.2 -2.1",
              rotation: "0 -40 0",
              width: 1.2,
              height: 1.2,
            },
          ],
        },
        {
          step: 13,
          isMain: true,
          name: "13-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "0 1.5 -2.1",
              position: "0 2.5 -2.1",
              rotation: "0 -10 0",
              width: 1.2,
              height: 1.2,
            },
            {
              infoPos: "2 2 -1",
              position: "4 4.5 -2",
              rotation: "0 -45 0",
              width: 2.8,
              height: 2.8,
            },
          ],
        },
        {
          step: 14,
          isMain: true,
          name: "14-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "1.2 2.8 -3.1",
              position: "2.2 2.8 -2.1",
              rotation: "0 -40 0",
              width: 1.2,
              height: 1.2,
            },
          ],
        },
        {
          step: 15,
          isMain: true,
          name: "15-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 2.5 -2",
              position: "2 0.3 -2",
              rotation: "0 -30 0",
              width: 3,
              height: 3.2,
            },
            {
              infoPos: "-0.2 2.8 -2.5",
              position: "-0.5 1 -4",
              rotation: "0 -5 0",
              width: 3,
              height: 3.2,
            },
          ],
        },
        {
          step: 16,
          isMain: true,
          name: "16-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 2 -2",
              position: "2 3.3 -2",
              rotation: "0 -50 0",
              width: 1.8,
              height: 1.8,
            },
          ],
        },
        {
          step: 17,
          isMain: true,
          name: "17-m",
          hasSub: false,
          SubAreas: [],
          clickAreasMain: [
            {
              infoPos: "2 3 -1.7",
              position: "4.5 0.8 -4.1",
              rotation: "0 -50 0",
              width: 14,
              height: 5,
            },
          ],
        },
      ],
    };
  },
  computed: {
    // a computed getter
    currScenario: function () {
      // `this` points to the vm instance
      return this.imgList[this.currentFrame - 1];
    },
  },
  mounted() {
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
    document.addEventListener("keydown", (e) => {
      console.log(e);
      if (e.keyCode === 37) this.next();
      if (e.keyCode === 39) this.prev();
      // log.textContent += ` ${e.code}`;
    });
    // if (typeof DeviceOrientationEvent.requestPermission === "function") {
    //   DeviceOrientationEvent.requestPermission()
    //     .then(permissionState => {
    //       if (permissionState === "granted") {
    //         window.addEventListener("deviceorientation", () => {});
    //       }
    //     })
    //     .catch(console.error);
    // } else {
    //   // handle regular non iOS 13+ devices
    // }
    // let camera = document.querySelector("#camera");
    // var position = new THREE.Vector3();
    // var quaternion = new THREE.Quaternion();
    // var euler = new THREE.Euler();
    // setInterval(() => {
    //   // console.log(camera.object3D.getWorldDirection(rotation));
    //   // console.log(camera.object3D.getWorldPosition(position));
    //   // console.log(camera.object3D.getWorldQuaternion(euler));
    //   // this.camRoatation = camera.object3D.getWorldQuaternion(quaternion).y;
    //   // this.camRoatation = camera.object3D.getWorldQuaternion(euler);
    // }, 800);
    // let text = 'לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב היושבב שערש';

    // var canvas = document.getElementById("canvas");
    // var ctx = canvas.getContext("2d");
    // console.log(canvas.width);
    // canvas.width = canvas.width * 3;
    // canvas.height = canvas.height * 3;
    // ctx.fillStyle = "rgba(255, 255, 255, 0.445)";
    // ctx.fillRect(0, 0, canvas.width, canvas.height);
    // ctx.font = "60px Arial";
    // ctx.fillStyle = "black";
    // ctx.fillText(text, 10, 50);
  },
  methods: {
    clickArea: function () {
      if (this.currScenario.hasSub) {
        this.isOnMain = !this.isOnMain;
      }
    },
    orient: function () {
      DeviceOrientationEvent.requestPermission().then((response) => {
        if (response == "granted") {
          console.log("fufu");
          window.addEventListener("deviceorientation", (e) => {
            // do something with e
          });
        }
      });
    },
    hoverAnim: function (entered) {
      let area = this.$refs.area[0];

      if (this.currScenario.hasSub) {
        if (entered) {
          area.setAttribute(
            "animation",
            "property: scale; to: 1.05 1.05 1.05; easing: easeInOutElastic; loop: 1; dur: 300"
          );
        } else {
          area.setAttribute(
            "animation",
            "property: scale; to: 1 1 1; easing: easeInOutElastic; loop: 1; dur: 300"
          );
        }
      }
    },
    hoverAnimToggle: function (entered) {
      let subArea = this.$refs.subArea[0];
      if (this.currScenario.hasSub) {
        if (entered) {
          subArea.setAttribute(
            "animation",
            "property: visible; to: true; easing: linear; loop: 1; dur: 100"
          );
        } else {
          subArea.setAttribute(
            "animation",
            "property: visible; to: false; easing: linear; loop: 1; dur: 100"
          );
        }
      }
    },
    hoverAnimInfo: function (entered) {
      let info = this.$refs.info[0];

      if (entered) {
        info.setAttribute(
          "animation",
          "property: scale; to: 0.95 0.95 0.95; easing: easeInOutElastic; loop: 1; dur: 200"
        );
      } else {
        info.setAttribute(
          "animation",
          "property: scale; to: 1 1 1; easing: easeInOutElastic; loop: 1; dur: 300"
        );
      }
    },
    hit: function () {
      console.log("hit");
    },
    next: function () {
      this.isOnMain = true;
      if (this.currentFrame === 17) {
        this.currentFrame = 1;
      } else {
        this.currentFrame++;
      }
    },
    prev: function () {
      if (this.isOnMain === false) this.isOnMain = true;
      else if (this.currentFrame === 1) {
        this.currentFrame = 17;
        // this.currentFrame--;
      } else this.currentFrame--;
    },
    reverse: function (str) {
      return str.split("").reverse().join("");
    },
    subTest: function (item) {
      console.log(item);
    },
    toggleFullScreen: function () {
      let elem = document.body;
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$blue: #3498db;
.ui-item {
  position: absolute;
  z-index: 2;
}

.ui-btn {
  cursor: pointer;
  color: unset;
  border: none;
  background: none;
  width: 50px;
  height: 50px;
  touch-action: manipulation;
  user-select: none;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  &:hover {
    opacity: 0.8;
  }
  &.info {
    // background-image: url("/img/icons/info-full.svg"); /* The image used */
  }
  &.map {
    // background-image: url("/img/icons/map-full.svg"); /* The image used */
  }
  &.toggle-screen {
    &.min {
      background-image: url("/img/icons/min-full.svg"); /* The image used */
    }
    &.full {
      background-image: url("/img/icons/full-full.svg"); /* The image used */
    }
  }
  &.next {
    background-image: url("/img/icons/arrow-full.svg"); /* The image used */
  }
  &.prev {
    background-image: url("/img/icons/arrow-full.svg"); /* The image used */
  }
}
.top {
  color: white;
  top: 10px;
  right: 10px;
  display: flex;
  .ui-btn {
    margin-right: 10px;
  }
}
.dialog-wrap {
  position: absolute;
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  z-index: 12;
  // align-items: center;
  .dialog {
    // width: 80%;
    // height: 60%;
    max-width: 600px;
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
    padding-top: 40px;
    text-align: right;
    color: white;
    border: 4px solid white;
    box-shadow: 10px 20px 10px -6px rgba(61, 61, 61, 0.575); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    position: relative;
    background: linear-gradient(
      to right,
      rgba(0, 132, 176, 0.8),
      rgba(0, 179, 219, 0.8)
    ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    .exit {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
      background: url("/img/icons/cancel.svg") no-repeat;
      background-size: cover;
      border: unset;
      height: 45px;
      width: 45px;
      transition: scale 0.3s;
      &:hover {
        transform: scale(0.95);
        opacity: 0.8;
      }
    }
  }
  .text {
    overflow-y: auto;
    // height: 80%;
    font-size: 30px;
    @media only screen and (max-width: 1100px) {
      font-size: 24px;
    }
  }
}

.arrow {
  bottom: 30px;
  // font-size: 35px;
  font-weight: bold;
  &.next {
    left: 30px;
    @media only screen and (max-width: 1100px) {
      left: 22px;
    }
  }
  &.prev {
    right: 30px;
    @media only screen and (max-width: 1100px) {
      right: 22px;
    }
  }
}
.overlay_debug {
  position: absolute;
  background: white;
  z-index: 5;
}
.next {
  transform: rotate(180deg);
}
</style>

<template>
  <div id="app">
    <Scene />
  </div>
</template> 

<script>
import Scene from "./components/Scene.vue";

export default {
  name: "App",
  components: {
    Scene,
  },
};
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
* {
  box-sizing: border-box;
}
#app {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
    // width: 100%;
  // height: 100%;
}

.loading-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #d5adc8;
  background-image: linear-gradient(315deg, #d5adc8 0%, #ff8489 74%);
  top: 0;
  z-index: 3;

}
.lds-dual-ring {
  display: inline-block;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .txt {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 94px;
  height: 94px;
  margin: 8px;
  border-radius: 50%;
  border: 15px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gradient {
  background: #00b4db; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #0083b0,
    #00b4db
  ) ; /* Chrome 10-25, Safari 5.1-6 */
}
</style>
